export default class FArea {
  constructor(
      id,
      sourceId,
      kode1,
      kode2,
      description,
      avatarImage,
      markerImage1,
      alias1,
      fdivisionBean,
      statusActive,
      fregionBean,
      lat,
      lon,
      created,
      modified,
      modifiedBy
  ) {
    this.id = id;
    this.sourceId = sourceId;
    this.kode1 = kode1;
    this.kode2 = kode2;
    this.description = description;
    this.avatarImage = avatarImage;
    this.markerImage1 = markerImage1;
    this.alias1 = alias1;
    this.fdivisionBean = fdivisionBean;
    this.statusActive = statusActive; // Boolean
    this.fregionBean = fregionBean;
    this.lat = lat;                   // Double
    this.lon = lon;                   // Double
    this.created = created;           // Expected as Date
    this.modified = modified;         // Expected as Date
    this.modifiedBy = modifiedBy;
  }
}