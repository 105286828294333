export default class FRegion {
  constructor(
      id,
      kode1,
      kode2,
      description,
      territoryCode,
      fdivisionBean,
      statusActive,
      sourceId,
      created,
      modified,
      modifiedBy
  ) {
    this.id = id;
    this.kode1 = kode1;
    this.kode2 = kode2;
    this.description = description;
    this.territoryCode = territoryCode;
    this.fdivisionBean = fdivisionBean;
    this.statusActive = statusActive;
    this.sourceId = sourceId;
    this.created = created;
    this.modified = modified;
    this.modifiedBy = modifiedBy;
  }
}
