export default class FSubArea {
  constructor(
      id,
      kode1,
      description,
      alias1,
      fareaBean,
      statusActive,
      zipCode,
      lat,
      lon,
      created,
      modified,
      modifiedBy
  ) {
    this.id = id;
    this.kode1 = kode1;
    this.description = description;
    this.alias1 = alias1;
    this.fareaBean = fareaBean;
    this.statusActive = statusActive;
    this.zipCode = zipCode;
    this.lat = lat;
    this.lon = lon;
    this.created = created;
    this.modified = modified;
    this.modifiedBy = modifiedBy;
  }
}